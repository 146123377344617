import React, { useRef, useState } from 'react';
import LogoSection from '../../../layout/MainLayout/LogoSection';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { load } from '@cashfreepayments/cashfree-js';
import { openSnackbar } from '../../../store/slices/snackbar';
import { useDispatch, useSelector } from '../../../store';
import { createPaymentSessionId, createRazorpayPaymentSessionId, createSubscriptionForPlan, getCouponData } from '../../../store/slices/payment';
import { getSessionData } from '../../../store/slices/session';
import axiosServices from '../../../utils/axios';
import CheckoutPageForm from './checkoutPageComp/checkoutPageForm';
import ShowConfetti from './checkoutPageComp/showConfetti';
import { buyNumber } from '../../../store/slices/phoneNumbers';
import { openConfirmationModal } from '../../../store/slices/confirmationModal';

const cashfree = await load({
    mode: "production", //or production,
    self: true,
    close: true,
});

const CheckoutPage = () => {

    const { data } = useSelector((state) => state.account)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const session_token = query.get('session_token')
    const razorpayWindowRef = useRef(null);

    if (!session_token) {
        //show error message
        dispatch(openSnackbar({
            open: true,
            message: 'Invalid valid session token',
            variant: 'alert',
            alert: { color: "error" },
            close: true
        }))
        navigate(-1)
    }

    //state to show 10 minute timer 
    const [timeLeft, setTimeLeft] = useState(600);

    const [values, setValues] = useState({
        name: `${data?.first_name} ${data?.last_name}` || '',
        email: data?.email || '',
        phone_number: data?.phone_number || '',
        address: '',
        city: '',
        postal_code: '',
        coupon_code: ''
    })

    const [error, setErrors] = useState({ phone_number: false, coupon_code: false })

    const [proceedPaymentLoading, setProceedPaymentLoading] = useState(false)
    const [paymentMethod, setPaymentMethod] = React.useState('razorpay');
    const [paymentType, setPaymentType] = React.useState('oneTimePayment');
    const [isCouponApplied, setIsCouponApplied] = React.useState(false);
    const [productDetails, setProductDetails] = useState(null)

    const [showConfetti, setShowConfetti] = useState(false);

    const { sessionData, sessionExpiration } = useSelector((state) => state.session)

    const { couponData, couponLoading } = useSelector((state) => state.payment)

    //detecting the browser back button so that confirmation modal of payment session cancel can be shown
    //Remaining

    //fetching the session data from the server using session token
    React.useEffect(() => {
        async function fetch() {
            try {
                await dispatch(getSessionData(session_token))
            }
            catch (error) {
                dispatch(openSnackbar({
                    open: true,
                    message: 'Error while fetching session data',
                    variant: 'alert',
                    alert: { color: "error" },
                    close: true
                }))

                //removing the timer from local storage
                localStorage.removeItem('startTime');

                navigate('/dashboard/numbers'); // Redirect to home page
            }
        }
        fetch()
    }, [])

    //setting the product details from session data to a state so that it can be modified
    React.useEffect(() => {
        //adding gst and discount in the product details
        const gstAmount = parseFloat((sessionData?.amount * 0.18).toFixed(2))

        setProductDetails({
            ...sessionData,
            gstAmount: gstAmount,
            discount: 0,
        })

    }, [sessionData])

    //if coupon data is changed means coupon has been applied
    React.useEffect(() => {
        if (couponData) {
            //showing the confetti effect
            if (!showConfetti) {
                setShowConfetti(true);
            }

            //adjusting the amount in the product details after discount apply
            const discount = parseFloat((productDetails?.amount * couponData?.discount).toFixed(2))
            const totalAmount = parseFloat((productDetails?.amount - discount).toFixed(2))

            //recalculating gst amount after discount on discounted price
            const gstAmount = parseFloat((totalAmount * 0.18).toFixed(2))

            setProductDetails({
                ...productDetails,
                discount: discount,
                gstAmount: gstAmount,
                totalAmount: totalAmount,
                coupon_code: couponData?.coupon_code
            })

            showMessage("success", "Coupon code applied successfully")
            setIsCouponApplied(true)
        }
    }, [couponData])


    //----------useEffect to show timer of 10 minutes-------------
    React.useEffect(() => {
        const sessionExpirationTime = new Date(sessionExpiration).getTime();
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const remaining = Math.max(sessionExpirationTime - currentTime, 0);
            setTimeLeft(remaining);

            if (remaining === 0) {
                razorpayWindowRef?.current?.close()

                clearInterval(interval);
                navigate('/dashboard/numbers');
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [sessionExpiration]);

    const formatTime = (milliseconds) => {
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    //----------------------------------------------------------------


    //function to handle on change of the input fields
    const handleChange = (e) => {
        setErrors({ ...error, [e.target.name]: false })

        setValues({ ...values, [e.target.name]: e.target.value })
    }

    //function to show messages
    const showMessage = (type, message) => {
        dispatch(openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: { color: type },
            close: true
        }))
    }

    //function to fetch the coupon details 
    const handleApplyCoupon = async () => {
        const { coupon_code } = values;
        if (!coupon_code) {
            setErrors({ ...error, coupon_code: true })
            return;
        }

        try {
            await dispatch(getCouponData(coupon_code))
        }
        catch (error) {
            showMessage("error", error?.message || "Something went wrong")
        }
    }

    //function to remove the applied coupon 
    const handleRemoveCoupon = async () => {
        setIsCouponApplied(false)
        setValues({
            ...values,
            coupon_code: ''
        })

        //recalculating the gst amount after coupon is removed because the prev 
        //gst amount was on discounted price.
        const gstAmount = parseFloat((productDetails?.amount * 0.18).toFixed(2))

        setProductDetails({
            ...productDetails,
            gstAmount: gstAmount,
            discount: 0,
            coupon_code: ''
        })

        showMessage("success", "Coupon code removed successfully")
    }


    //function to handle payment through cashfree 
    const handleCashFreePayment = async () => {

        //getting the data from the session that we have sated while creating payment session
        let amount;
        if (isCouponApplied) {
            amount = productDetails?.totalAmount + productDetails?.gstAmount
        }
        else {
            amount = productDetails?.amount + productDetails?.gstAmount
        }

        const currency = productDetails?.currency
        const callback_url = productDetails?.callback_url
        const productId = productDetails?.productId
        const type = productDetails?.type
        const product = productDetails?.product
        const coupon_code = productDetails?.coupon_code
        const discount = productDetails?.discount

        if (!amount || !currency || !productId || !type || !callback_url) {
            showMessage("error", "Invalid session data")
            return;
        }

        const data = {
            amount,
            currency,
            callback_url,
            productId,
            type,
            phone_number: values?.phone_number?.replace(/\s+/g, ''),
            name: values?.name,
            email: values?.email,
            product, coupon_code, discount
        }

        let paymentSessionDetails;
        try {
            paymentSessionDetails = await dispatch(createPaymentSessionId(data))

        }
        catch (error) {
            showMessage("error", error?.message || 'Failed to create payment session!')
            return;
        }
        finally {
            setProceedPaymentLoading(false)
        }


        if (paymentSessionDetails) {
            let checkoutOptions = {
                paymentSessionId: paymentSessionDetails?.payment_session_id,
                redirectTarget: "_self" // "_modal"
            }

            cashfree.checkout(checkoutOptions).then(async (result) => {
                if (result.error) {
                    showMessage("error", 'Payment failed')

                    navigate(`/dashboard/post-transaction?order_id=${paymentSessionDetails?.order_id}&payment_mode=${paymentMethod}`)
                }
                if (result.redirect) {
                    // This will be true when the payment redirection page couldn't be opened in the same window
                    showMessage("error", 'Something went wrong!')

                }
                if (result.paymentDetails) {
                    //If payment successful buying the product
                    const order_id = paymentSessionDetails?.order_id
                    showMessage("success", "Payment successful")

                    setTimeout(() => {
                        navigate(`/dashboard/post-transaction?order_id=${order_id}&payment_mode=cashfree`)
                    }, 1000)
                }
            });
        }
    }

    const handleRazorpayOneTimePayment = async (dataToSend) => {
        let paymentSessionDetails;
        try {
            paymentSessionDetails = await dispatch(createRazorpayPaymentSessionId(dataToSend))
        }
        catch (error) {
            showMessage("error", error?.message || 'Failed to create payment session!')
            return;
        }
        finally {
            setProceedPaymentLoading(false)
        }

        if (paymentSessionDetails) {
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_zwsHSaUiFFxGtG',
                amount: dataToSend?.amount,
                currency: dataToSend?.currency,
                name: 'Ringus',
                image: 'https://ringus.s3.ap-south-1.amazonaws.com/website_assets/ringus_logo.png',
                description: 'Test Transaction',
                order_id: paymentSessionDetails?.id,
                handler: async (response) => {
                    const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

                    // Verify the payment on the server
                    try {
                        const verificationResponse = await axiosServices.post('/payment/razorpay/verify-payment', {
                            order_id: razorpay_order_id,
                            payment_id: razorpay_payment_id,
                            signature: razorpay_signature,
                        });

                        if (verificationResponse.data.message === 'Payment verified successfully') {
                            showMessage("success", "Payment successful")

                            setTimeout(() => {
                                navigate(`/dashboard/post-transaction?order_id=${razorpay_order_id}&payment_mode=razorpay`)
                            }, 1000)
                        }
                    }
                    catch (error) {
                        showMessage("error", "Error in verifying transaction")
                    }
                },
                prefill: {
                    name: `${values?.name}`,
                    email: values?.email,
                    contact: values?.phone_number,
                },
                notes: {
                    address: values?.address,
                },
                theme: {
                    color: '#169CD9',
                },
                modal: {
                    ondismiss: function () {
                        showMessage("error", 'Payment cancelled')
                    }
                }
            };

            const razropayWindow = new window.Razorpay(options);

            //storing the razorpay window in a ref so that we can close it later
            razorpayWindowRef.current = razropayWindow

            razropayWindow.on("payment.failed", function (response) {
                showMessage("error", response.error.message || "Payment failed")
            });

            razropayWindow.open();
        }
    }

    const handleRazorpayRecurringPayment = async (dataToSend) => {
        try {

            let paymentSessionDetails;
            try {
                paymentSessionDetails = await dispatch(createSubscriptionForPlan(dataToSend))
            }
            catch (error) {
                showMessage("error", error?.message || 'Failed to create payment session!\n please try again later')
                return;
            }
            finally {
                setProceedPaymentLoading(false)
            }

            if (paymentSessionDetails) {
                const options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_zwsHSaUiFFxGtG',
                    subscription_id: paymentSessionDetails?.subscription?.id,
                    plan_id: paymentSessionDetails?.plan?.id,
                    name: 'Ringus',
                    description: 'Test Subscription',
                    image: 'https://ringus.s3.ap-south-1.amazonaws.com/website_assets/ringus_logo.png',
                    handler: async (response) => {
                        const { razorpay_subscription_id, razorpay_payment_id, razorpay_signature } = response;

                        const verificationResponse = await axiosServices.post('/payment/razorpay/verify-payment', {
                            order_id: razorpay_subscription_id,
                            payment_id: razorpay_payment_id,
                            signature: razorpay_signature,
                        });

                        if (verificationResponse.data.message === 'Payment verified successfully') {
                            showMessage("success", "Payment successful")

                            setTimeout(() => {
                                navigate(`/dashboard/post-transaction?order_id=${razorpay_subscription_id}&payment_mode=razorpay`)
                            }, 1000)
                        }
                    },
                    prefill: {
                        name: `${values?.name}`,
                        email: values?.email,
                        contact: values?.phone_number,
                    },
                    theme: {
                        color: '#169CD9',
                    },
                    modal: {
                        ondismiss: () => {
                            showMessage("info", "Subscription flow dismissed");
                        }
                    }
                };

                const razropayWindow = new window.Razorpay(options);

                //storing the razorpay window in a ref so that we can close it later
                razorpayWindowRef.current = razropayWindow

                razropayWindow.on("payment.failed", function (response) {
                    showMessage("error", response.error.message)
                });

                razropayWindow.open();
            }
        }
        catch (error) {
            showMessage("error", error?.message || 'Failed to create payment session!')
            return;
        }
    }

    const handleRazorpayPayment = async () => {
        let amount;
        if (isCouponApplied) {
            amount = productDetails?.totalAmount + productDetails?.gstAmount
        }
        else {
            amount = productDetails?.amount + productDetails?.gstAmount
        }

        const currency = productDetails?.currency
        const callback_url = productDetails?.callback_url
        const productId = productDetails?.productId
        const type = productDetails?.type
        const product = productDetails?.product
        const coupon_code = productDetails?.coupon_code
        const discount = productDetails?.discount
        const iso_code = productDetails?.iso_code

        if (amount < 0 || !currency || !productId || !type || !callback_url) {
            showMessage("error", "Invalid session data")
            return;
        }

        const dataToSend = {
            amount,
            currency,
            callback_url,
            productId,
            type,
            phone_number: values?.phone_number?.replace(/\s+/g, ''),
            name: values?.name,
            email: values?.email,
            product,
            coupon_code,
            iso_code,
            discount
        }

        console.log("amount", amount)

        if (amount === 0) {
            //purchase interview free 
            if (type === 'NORMAL') {
                await dispatch(buyNumber({
                    phone_number_id: productId,
                    product: product,
                    coupon_code: coupon_code,
                    iso_code: iso_code,
                    name: values?.name,
                    email: values?.email,
                    phone_number: values?.phone_number?.replace(/\s+/g, ''),
                }))
                    .then(() => {
                        setTimeout(() => {
                            setProceedPaymentLoading(false)
                            //open congratulations modal
                            dispatch(
                                openConfirmationModal({
                                    open: true,
                                    message: "This action is not reversible.",
                                    modalType: "PURCHASED_PRODUCT",
                                    handleConfirm: async () => {
                                        window.location.href = `/dashboard/numbers`
                                    },
                                })
                            );
                        }, 2000)
                    })
                    .catch((err) => {
                        setProceedPaymentLoading(false)
                    })
            }
        }
        else if (type === 'SUBSCRIPTION') {
            if (paymentType === 'oneTimePayment') {
                await handleRazorpayOneTimePayment(dataToSend)
            }
            else {
                const data = { type, productId, product, coupon_code }
                await handleRazorpayRecurringPayment(data)
            }
        }
        else if (type === 'NORMAL') {
            await handleRazorpayOneTimePayment(dataToSend)
        }
    }

    const handleCheckout = async () => {

        try {
            //phone_number is mandatory
            if (!values.phone_number) {
                setErrors({ ...error, phone_number: true })
                return;
            }
            const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
            const isCorrect = phoneRegex.test(values.phone_number);

            if (!isCorrect) {
                setErrors({ ...error, phone_number: true })
                return;
            }

            //if everything is correct then proceed to create payment session
            setProceedPaymentLoading(true)


            if (paymentMethod === 'cashfree') {
                await handleCashFreePayment()
            }
            else if (paymentMethod === 'razorpay') {
                await handleRazorpayPayment()
            }
        }
        finally {
            //removing the timer from local storage
            localStorage.removeItem('startTime');
        }
    }


    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
            <div className='p-4 flex items-center gap-3'>
                <LogoSection />
                <h1 className='text-2xl font-bold'>
                    Ring
                    <span className='text-[#169CD9]'>Us</span>
                </h1>
            </div>
            <CheckoutPageForm
                formatTime={formatTime}
                timeLeft={timeLeft}
                values={values}
                error={error}
                handleChange={handleChange}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                handleCheckout={handleCheckout}
                proceedPaymentLoading={proceedPaymentLoading}
                handleApplyCoupon={handleApplyCoupon}
                couponLoading={couponLoading}
                isCouponApplied={isCouponApplied}
                productDetails={productDetails}
                handleRemoveCoupon={handleRemoveCoupon}
            />

            <ShowConfetti showConfetti={showConfetti} setShowConfetti={setShowConfetti} />
        </div>
    );
};

export default CheckoutPage;
