/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { SAVE_USER_DATA } from '../actions';
import { getCookie } from '../../utils';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';
import { openSnackbar } from './snackbar.js';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    user: null,
    allUsers: null,
    userAllSessions: null,
    loading: false,
    loadingAllSessions: false,
    loggingOutAllSessions: false,
    loggingOutSingleSession: false,
    updateSettingLoading: false,
    contactingUs: false,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        isLoading(state, action) {
            state.loading = action.payload;
        },
        isLoadingAllSessions(state, action) {
            state.loadingAllSessions = action.payload;
        },

        isLoggingOutAllSessions(state, action) {
            state.loggingOutAllSessions = action.payload;
        },
        isLoggingOutSingleSessions(state, action) {
            state.loggingOutSingleSession = action.payload;
        },

        isUpdateSettingLoading(state, action) {
            state.updateSettingLoading = action.payload;
        },

        isContactingUsLoading(state, action) {
            state.contactingUs = action.payload;
        },

        setUserDataSuccess(state, action) {
            state.user = action.payload;
        },

        setAllUsersDataSuccess(state, action) {
            state.allUsers = action.payload;
        },

        setAllUserSessionDataSuccess(state, action) {
            state.userAllSessions = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getUserData() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/user/get-user`);
            dispatch(slice.actions.setUserDataSuccess(response.data?.data));

            return response.data?.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getAllUsers(search) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/user/get-users?search=${search}`);
            dispatch(slice.actions.setAllUsersDataSuccess(response.data?.data));
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function updateUser(data) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.put(`/user/update-user`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            handleSuccess(response, "User updated successfully", dispatch);

            dispatch({
                type: SAVE_USER_DATA,
                payload: {
                    user: response?.data?.data
                }
            });

            return response?.data?.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function updateUserSettings(data) {
    return async () => {
        try {
            dispatch(slice.actions.isUpdateSettingLoading(true));

            const response = await axios.put(`/user/settings/update`, data);
            handleSuccess(response, "Settings updated successfully", dispatch);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isUpdateSettingLoading(false));
        }
    }
}

export function getAccessToken() {
    return async () => {
        try {
            const refreshToken = getCookie('refreshToken')
            console.log("Cookie refresh token: " + refreshToken)

            const response = await axios.post(`/auth/access-token`, {
                refreshToken: refreshToken
            });
            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
            throw err;
        }
    }
}

export function getAllActiveSessions() {
    return async () => {
        try {

            dispatch(slice.actions.isLoadingAllSessions(true));

            const response = await axios.get(`/user/active-sessions`);
            dispatch(slice.actions.setAllUserSessionDataSuccess(response.data?.data));

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoadingAllSessions(false));
        }
    }
}

export function logoutAllActiveSessions() {
    return async () => {
        try {

            dispatch(slice.actions.isLoggingOutAllSessions(true));

            const response = await axios.post(`/auth/logout/all-sessions`);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoggingOutAllSessions(false));
        }
    }
}

export function logoutAActiveSession(data) {
    return async () => {
        try {

            dispatch(slice.actions.isLoggingOutSingleSessions(true));

            const response = await axios.post(`/auth/logout/session`, data);

            handleSuccess(response, "User logged out successfully", dispatch);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoggingOutSingleSessions(false));
        }
    }
}


export function contactUs(data) {
    return async () => {
        try {
            dispatch(slice.actions.isContactingUsLoading(true));

            const response = await axios.post(`/miscellaneous/contact-us/create`, data);

            dispatch(openSnackbar({
                open: true,
                message: "Details sent successfully. We will get back to you shortly.",
                variant: 'alert',
                alert: { color: "success" },
                close: true
            }));

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isContactingUsLoading(false));
        }
    }
}
