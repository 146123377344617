import PropTypes, { element } from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import BreadcrumbsNav from './BreadcrumbsTwo';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = false,
            boxShadow,
            children,
            element,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            hideDivider = false,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[300] + 98,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow ||
                            (theme.palette.mode === 'dark' ? '0 2px 14px 0 rgb(33 150 243 / 10%)' : '0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    minHeight: '97vh',
                    ...sx
                }}
            >
                <Grid container alignItems='center' justifyContent={'space-between'} >
                    <Grid item className='w-fit xs:w-1/2'>
                        {/* card header and action */}
                        {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                        {darkTitle && title && (
                            <CardHeader sx={headerSX} title={<Typography variant="h3" padding={0}>{title}</Typography>} action={secondary} />
                        )}
                    </Grid>

                    <Grid item className='xss:mb-2 sm:mb-0 w-fit ml-[20px] xs:w-1/2 xs:ml-[0px]'>
                        <Grid container justifyContent='end'>
                            {element}
                        </Grid>
                    </Grid>
                </Grid>

                {/* content & header divider */}
                {title && !hideDivider && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    element: PropTypes.element,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    hideDivider: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
