import Routes from "./routes";
import { FirebaseProvider as AuthProvider } from "./contexts/FirebaseContext";
import { PermissionProvider } from "./contexts/PermissionContext";

import Locales from "./ui-elements/Locales";
import ThemeCustomization from './themes'
import ConfirmationModal from "./views/components/others/ConfirmationModal";
import Snackbar from "./ui-elements/Snackbar";
import NetworkStatus from "./ui-elements/NetworkStatus/NetworkStatus";
import LoadingOverlay from "./ui-elements/overlayLoading";
import DetailsDrawer from "./views/components/others/DetailsDrawer";

function App() {
  return (
    <ThemeCustomization>
      <Locales>
        <AuthProvider>
          <PermissionProvider>
            <>
              <Routes />
              <ConfirmationModal />
              <DetailsDrawer />
              <Snackbar />
              <NetworkStatus />
              <LoadingOverlay />
            </>
          </PermissionProvider>
        </AuthProvider>
      </Locales>
    </ThemeCustomization>
  );
}

export default App;
