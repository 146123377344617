/* eslint-disable */
import React, { useEffect } from 'react';
import SignIn from '../../components/auth/Singnin';
import Styles from '../../../styles/landingpage.module.scss';
import Header from '../../../ui-elements/header';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import SignUp from '../../components/auth/SignUp';
import ForgetPassword from '../../components/auth/ForgetPassword';
import { useSelector } from '../../../store';
import TwoFaAuthPage from '../../components/auth/TwoFaAuthPage';

function index() {
    const { jwtToken } = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.account)

    const pathname = window.location.pathname

    useEffect(() => {
        const token = window.localStorage.getItem('access_token');
        if (isLoggedIn && token) {
            window.location.href = '/dashboard/quick-start'
        }
    }, [isLoggedIn, navigate, jwtToken]);


    return (
        <div className={Styles.container}>
            <Header />
            {pathname === '/login' ?
                <SignIn />
                :
                pathname === '/sign-up' ?
                    <SignUp />
                    :
                    pathname === '/forgot-password' ?
                        <ForgetPassword />
                        :
                        pathname === '/auth/two-factor' ?
                            <TwoFaAuthPage />
                            :
                            null
            }
        </div>
    );
}

export default index;
