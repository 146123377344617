// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconPhone, IconRobot, IconHistory, IconHeadset, IconUser, IconCreditCard, IconCode, IconPhoneOutgoing } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconPhone,
    IconRobot,
    IconHistory,
    IconHeadset,
    IconUser,
    IconCreditCard,
    IconCode,
    IconPhoneOutgoing
};

// ==============================|| MENU ITEMS ||============================== //

const UnderMaintenance = ({ name }) => {
    return (
        <div className='flex gap-2'>
            <p>{name}</p>
            <sup className='text-[10px] font-semibold mt-[8px]'>Beta</sup>
        </div>
    )
}

const adminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id=" " />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'numbers',
            title: <FormattedMessage id="Numbers" />,
            type: 'item',
            url: '/dashboard/numbers',
            icon: icons.IconPhone,
            tempIcon: 'sharp-call'
        },
        {
            id: 'assistant',
            title: <FormattedMessage id="Assistants" />,
            type: 'collapse',
            icon: icons.IconRobot,
            children: [
                {
                    id: 'agents',
                    title: <FormattedMessage id="Agents" />,
                    type: 'item',
                    url: '/dashboard/agents',
                    breadcrumbs: false
                },
                // {
                //     id: 'personal_assistant',
                //     title: <FormattedMessage id="Personal Assistant" />,
                //     type: 'item',
                //     url: '/dashboard/personal-assistant',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'chat_bots',
                //     title: <FormattedMessage id="Chat Bot" />,
                //     type: 'item',
                //     url: '/dashboard/chatBots',
                //     breadcrumbs: false
                // },
            ]

        },
        {
            id: 'call history',
            title: <FormattedMessage id="Call History" />,
            type: 'item',
            url: '/dashboard/call-history',
            icon: icons.IconHistory,
            breadcrumbs: false
        },
        {
            id: 'outbound_call',
            title: <FormattedMessage id="Outbound Call" />,
            type: 'item',
            url: '/dashboard/outbound-call',
            icon: icons.IconPhoneOutgoing,
            breadcrumbs: false
        },
        // {
        //     id: 'subscription',
        //     title: <FormattedMessage id="Subscription" />,
        //     type: 'item',
        //     url: '/dashboard/subscription-plan',
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false
        // },
        {
            id: 'developer',
            title: <UnderMaintenance name="Developer" />,
            type: 'item',
            url: '/dashboard/developer/api-keys',
            icon: icons.IconCode,
            breadcrumbs: false
        },
        {
            id: 'support',
            title: <FormattedMessage id="Support" />,
            type: 'item',
            url: '/dashboard/support',
            icon: icons.IconHeadset,
            breadcrumbs: false
        },
    ]
};

export default adminDashboard;
