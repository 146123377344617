/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    organizations: null,
    singleOrganization: null,
    members: null,
    memberPermissions: null,
    auditLogs: null,
    singleAuditLog: null,

    loading: false,
    singleOrgLoading: false,
    updateSettingLoading: false,
    membersLoading: false,
    updateMemberLoading: false,
    invitingMember: false,
    auditLogsLoading: false,
    singleAuditLogLoading: false,

    totalCount: null,
    totalAuditLogs: null,
    totalAuditLogsPages: null,
};

const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        isLoading(state, action) {
            state.loading = action.payload;
        },
        isSingleOrgLoading(state, action) {
            state.singleOrgLoading = action.payload;
        },
        isUpdateSettingLoading(state, action) {
            state.updateSettingLoading = action.payload;
        },
        isMembersLoading(state, action) {
            state.membersLoading = action.payload;
        },
        isUpdateMemberLoading(state, action) {
            state.updateMemberLoading = action.payload;
        },
        isInvitingMemberLoading(state, action) {
            state.invitingMember = action.payload;
        },
        isAuditLogsLoading(state, action) {
            state.auditLogsLoading = action.payload;
        },
        isSingleAuditLogLoading(state, action) {
            state.singleAuditLogLoading = action.payload;
        },

        setOrganizationsDataSuccess(state, action) {
            state.organizations = action.payload;
        },

        setSingleOrganizationDataSuccess(state, action) {
            state.singleOrganization = action.payload;
        },

        setOrganizationMembersDataSuccess(state, action) {
            state.members = action.payload?.data;
            state.totalCount = action.payload?.totalCount;
        },

        setOrganizationMemberPermissionsDataSuccess(state, action) {
            state.memberPermissions = action.payload?.permissions;
        },

        updateOrganizationDataSuccess(state, action) {
            const index = state.organizations.findIndex(org => org.org_id === action.payload.id);
            const orgData = state.organizations[index];
            const newData = { ...orgData, ...action.payload.data };
            state.organizations[index] = newData
        },

        setAuditLogsDataSuccess(state, action) {
            state.auditLogs = action.payload?.data;
            state.totalAuditLogs = action.payload?.totalCount;
            state.totalAuditLogsPages = action.payload?.totalPages;
        },

        setSingleAuditLogDataSuccess(state, action) {
            state.singleAuditLog = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getUserOrganizations() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/organization/get-all`);

            const userOrganizations = response.data?.data;
            dispatch(slice.actions.setOrganizationsDataSuccess(userOrganizations));

            return userOrganizations
        }
        catch (err) {
            // handleError(err, dispatch);
            throw err;
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getSingleOrganizationData(org_id) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleOrgLoading(true));

            const response = await axios.get(`/organization/get-one/${org_id}`);
            dispatch(slice.actions.setSingleOrganizationDataSuccess(response.data?.data));
        }
        catch (err) {
            handleError(err, dispatch);
            throw err;
        }
        finally {
            dispatch(slice.actions.isSingleOrgLoading(false));
        }
    }
}

export function getOrganizationMemberPermissions() {
    return async () => {
        try {
            const response = await axios.get(`/organization/member/permissions`);
            dispatch(slice.actions.setOrganizationMemberPermissionsDataSuccess(response.data?.data));

            return response.data?.data;
        }
        catch (err) {
            // handleError(err, dispatch);
            throw err;
        }
    }
}

export function updateOrganization(org_id, data) {
    return async () => {
        try {
            dispatch(slice.actions.isUpdateSettingLoading(true));

            const response = await axios.put(`/organization/update/${org_id}`, data);

            dispatch(slice.actions.updateOrganizationDataSuccess(
                { data, id: org_id }
            ))

            handleSuccess(response, "Organization setting updated successfully", dispatch);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isUpdateSettingLoading(false));
        }
    }
}

export function getOrganizationMembersData({ search, page, limit }) {
    return async () => {
        try {
            dispatch(slice.actions.isMembersLoading(true));

            const response = await axios.get(`/organization/members?search=${search}&page=${page}&limit=${limit}`);

            dispatch(slice.actions.setOrganizationMembersDataSuccess(response.data));
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isMembersLoading(false));
        }
    }
}

export function updateOrganizationMember(data) {
    return async () => {
        try {
            dispatch(slice.actions.isUpdateMemberLoading(true));

            const response = await axios.put(`/organization/member/update`, data);

            handleSuccess(response, "Member updated successfully", dispatch);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isUpdateMemberLoading(false));
        }
    }
}

export function inviteMember(data) {
    return async () => {
        try {
            dispatch(slice.actions.isInvitingMemberLoading(true));

            const response = await axios.post(`/organization/member/invite`, data);

            handleSuccess(response, "Member invitation successfully", dispatch);

            return response.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isInvitingMemberLoading(false));
        }
    }
}

export function acceptInvitation(invite_code) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            //adding some delay to for user experience.
            await new Promise(resolve => setTimeout(resolve, 1000));

            const response = await axios.post(`/organization/member/accept-invite?invite_code=${invite_code}`);

            handleSuccess(response, "Successfully added to the organization", dispatch);

            return response.data?.data
        }
        catch (err) {
            throw err
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getAuditLogs({ page, limit }) {
    return async () => {
        try {
            dispatch(slice.actions.isAuditLogsLoading(true));

            const response = await axios.get(`/organization/audit-logs?page=${page}&limit=${limit}`);

            dispatch(slice.actions.setAuditLogsDataSuccess(response.data));

            return response.data?.data
        }
        catch (err) {
            handleError(err, dispatch);
            throw err;
        }
        finally {

            await new Promise((resolve) => setTimeout(resolve, 1000))

            dispatch(slice.actions.isAuditLogsLoading(false));
        }
    }
}

export function getSingleAuditLog({ audit_log_id }) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleAuditLogLoading(true));

            const response = await axios.get(`/organization/audit-log/${audit_log_id}`);

            dispatch(slice.actions.setSingleAuditLogDataSuccess(response.data.data));

            return response.data?.data
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isSingleAuditLogLoading(false));
        }
    }
}
