import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Styles from "../../../styles/login.module.scss";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

import useAuth from '../../../hooks/useAuth';

import googleIcon from '../../../assets/images/google.png'
import { LoadingButton } from '@mui/lab';
import { openSnackbar } from '../../../store/slices/snackbar';
import { useDispatch, useSelector } from '../../../store';
import { getFirebaseErrorMessage } from './getFirebaseErrorMessage';
import { Icon } from '@iconify/react/dist/iconify.js';
import { SAVE_USER_DATA } from '../../../store/actions';
import { getDeviceDetails } from '../../../utils';

// ===============================|| JWT LOGIN ||=============================== //

const FirebaseLogin = ({ loginProp, ...others }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        firebaseEmailPasswordSignIn, saveUserData,
        firebaseGoogleSignIn,
        saveUserDataGoogleLogin,
    } = useAuth();

    const [showPassword, setShowPassword] = React.useState(false);
    const [googleLoginLoading, setGoogleLoginLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const showMessage = (type, message) => {
        dispatch(openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: { color: type },
        }));
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const getUserDeviceDetails = async () => {
        const data = await getDeviceDetails();
        return {
            device_name: data.device_name,
            ip_address: data.ip_address,
            platform: data.platform
        }
    }

    const googleLogin = async () => {
        try {
            setGoogleLoginLoading(true)
            //logging in with google 
            await firebaseGoogleSignIn()
                .then(async (response) => {
                    //After login with google is successful then saving into the mongoDB
                    const userDetails = response?.user;
                    const uid = userDetails?.uid;
                    const device_info = await getUserDeviceDetails()

                    const data = {
                        first_name: userDetails?.displayName,
                        email: userDetails?.email,
                        phone_number: userDetails?.phoneNumber,
                        uid: userDetails?.uid,
                        firebase_token: userDetails?.accessToken,
                        device_info
                    }

                    try {

                        await saveUserDataGoogleLogin(data)
                    }
                    catch (error) {
                        //if two factor enabled
                        const newData = {
                            ...data,
                            auth_type: 'google',
                        }
                        dispatch({
                            type: SAVE_USER_DATA,
                            payload: {
                                user: newData,
                            }
                        });
                        if (error?.errorCode === 'TWO_FACTOR_REQUIRED') {
                            navigate('/auth/two-factor')
                            return;
                        }
                        setErrorMessage(error?.message || "Error occurred while logging in")
                    }
                    finally {
                        setGoogleLoginLoading(false)
                    }

                })
                .catch((error) => {
                    const message = getFirebaseErrorMessage(error)
                    if (message === 'Login popup closed') {
                        return;
                    }
                    showMessage("error", message || error?.error || "Error occurred while logging in!")
                })
        }
        catch (err) {
            setErrorMessage(err?.message || err?.error || "Something went wrong.")
        }
        finally {
            setGoogleLoginLoading(false)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, validateForm }) => {
                    try {
                        setSubmitting(true)

                        //firebase login event
                        await firebaseEmailPasswordSignIn(values.email, values.password)
                            .then(async (res) => {
                                //If firebase login successful then saving the user data into mongoDB and returning
                                //jwt token
                                const userDetails = res?.user;
                                const device_info = await getUserDeviceDetails()
                                const dataToSend = {
                                    uid: userDetails.uid,
                                    firebase_token: userDetails?.accessToken,
                                    device_info: device_info
                                }
                                try {
                                    await saveUserData(dataToSend)
                                }
                                catch (error) {
                                    //if two factor enabled
                                    const newData = {
                                        ...dataToSend,
                                        auth_type: 'email-password',
                                    }
                                    dispatch({
                                        type: SAVE_USER_DATA,
                                        payload: {
                                            user: newData,
                                        }
                                    });

                                    if (error?.errorCode === 'TWO_FACTOR_REQUIRED') {
                                        navigate('/auth/two-factor')
                                        return;
                                    }
                                    setErrorMessage(error?.message || "Something went wrong.")
                                }
                            })
                            .catch((error) => {
                                const message = getFirebaseErrorMessage(error)
                                setErrorMessage(message || error?.error || "Something went wrong.")
                            })
                    }

                    catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: "Failed" });

                        setErrorMessage(err?.message || err?.error || "Something went wrong. Please check your credentials.")
                    }
                    finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ setErrors, errors, handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <div className='text-black'>
                            <h2 className='text-2xl font-semibold'>Welcome Back</h2>
                            <h2 className='text-base'>Log in to your account</h2>
                        </div>
                        <Box sx={{ mt: 2 }}>
                            <Button
                                className='outlined-button bg-white'
                                variant="outlined"
                                fullWidth size="large"
                                onClick={googleLogin}
                                loading={googleLoginLoading}
                                style={{
                                    padding: '10px 20px',
                                }}
                            >
                                <img src={googleIcon} alt='icon' className='h-5 w-5 mr-3' />
                                {googleLoginLoading ?
                                    <CircularProgress style={{ height: '20px', width: '20px' }} />
                                    :
                                    <span>Google</span>}
                            </Button>
                        </Box>
                        <Divider textAlign="center" className='text-tertiary mt-5 mb-5'>
                            Or with email and password
                        </Divider>

                        {errorMessage ?
                            <div className={`${Styles.error_box} p-3`}>
                                {errorMessage}
                            </div>
                            : <></>
                        }
                        <div className={Styles.form}>
                            <div className='flex flex-col gap-4'>
                                <FormControl fullWidth error={Boolean(errors.email)}>
                                    <OutlinedInput
                                        placeholder='Email Address'
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setErrors({ ...errors, email: null })
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="carbon:email" className='text-[20px]' />
                                            </InputAdornment>
                                        }
                                        inputProps={{ className: Styles.input }}
                                    />

                                    {errors.email && (
                                        <FormHelperText className={Styles.error_message} id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl fullWidth error={Boolean(errors.password)}>
                                    <OutlinedInput
                                        placeholder='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setErrors({ ...errors, password: null })
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Icon icon="clarity:lock-line" className='text-[23px]' />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ?
                                                        <Icon icon="clarity:eye-show-line" className='text-[25px]' /> :
                                                        <Icon icon="clarity:eye-hide-line" className='text-[25px]' />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        inputProps={{ className: Styles.input }}
                                    />
                                    {errors.password && (
                                        <FormHelperText className={Styles.error_message} id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>

                            {errors.submit && (
                                <Box sx={{ mt: 1 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                            <Box sx={{ mt: 2 }}>
                                <LoadingButton
                                    color="secondary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    style={{ padding: '12px 0px' }}
                                >
                                    Sign In
                                </LoadingButton>
                            </Box>
                            <Grid container alignItems="center" justifyContent="center" marginTop={1}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle1"
                                        component={Link}
                                        to='/forgot-password'
                                        color="#000"
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        Forgot Password?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                )}
            </Formik>
            {/* :
            <TwoFaForm />
        } */}
        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number
};

export default FirebaseLogin;
