import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from '../../../menu-items';
import NavGroup from './NavGroup';
import useConfig from '../../../hooks/useConfig';

import LAYOUT_CONST from '../../../constant';
import { HORIZONTAL_MAX_ITEM } from '../../../config';
import { useSelector } from '../../../store';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    const { layout } = useConfig();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { active_org } = useSelector(state => state.account)

    //Setting up the sidebar tab view based on the permissions
    const [newMenusItems, setNewMenuItems] = useState([])

    //Setting up the sidebar tab view based on the permissions

    const filterMenuItems = () => {
        const filteredMenuItems = menuItem?.items.map((item) => {
            const { children, ...restItems } = item;

            const newChildren = children?.map(child => {
                if (child.id === 'assistant' && active_org?.org_permissions?.is_assistant_enabled) {
                    const myNewChildren = child?.children.map(myChild => {
                        if (myChild.id === 'agents') {
                            return {
                                ...myChild,
                                disabled: false
                            }
                        }
                        if (myChild.id === 'personal_assistant') {
                            return myChild;
                        }
                        if (myChild.id === 'chat_bots') {
                            return myChild;
                        }
                    }).filter(Boolean);

                    if (myNewChildren.length > 0) {
                        return { ...child, children: myNewChildren };
                    }
                } else if (child.id === 'outbound_call' && active_org?.org_permissions?.is_obc_enabled) {
                    return child;
                } else if (child.id === 'developer' && active_org?.org_permissions?.is_api_key_enabled) {
                    return child;
                }

                if ((child.id === 'numbers')
                    || (child.id === 'call history')
                    || (child.id === 'subscription')
                    || child.id === 'support'
                    || child.id === 'profile') {
                    return child;
                }
            }).filter(Boolean);

            return { ...restItems, children: newChildren };
        });

        return filteredMenuItems;
    };

    // Update the menu items when `active_org` is fetched
    useEffect(() => {
        if (active_org) {
            const object = {
                items: filterMenuItems()
            };
            setNewMenuItems(object);
        }
    }, [active_org]);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = newMenusItems?.items?.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < newMenusItems?.items?.length) {
        lastItemId = newMenusItems?.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = newMenusItems?.items?.slice(lastItem - 1, newMenusItems?.items?.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }


    const navItems = newMenusItems?.items?.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
