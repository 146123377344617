
import axios from 'axios';
import { dispatch } from '../store';
import { openSnackbar } from '../store/slices/snackbar';

const baseURL = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? process.env.REACT_APP_API_URL : 'http://localhost:8000/api/'

const axiosServices = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

// Add a request interceptor
axiosServices.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('access_token');
        const active_org = JSON.parse(localStorage.getItem('active_org'));
        const session_id = localStorage.getItem('session_id');

        config.headers.Authorization = `Bearer ${token}`;
        config.headers['ringus-organization'] = active_org?.org_id;
        config.headers['session-id'] = session_id
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.code === "ERR_NETWORK") {
            dispatch(openSnackbar({
                open: true,
                message: error?.message || 'Error occurred',
                variant: 'alert',
                alert: { color: 'error' },
                close: true
            }));
        }

        return Promise.reject((error.response && error.response?.data) || 'Wrong Services')
    }
);

export default axiosServices;
