import LAYOUT_CONST from './constant';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/numbers';
export const HORIZONTAL_MAX_ITEM = 6;

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 2,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', 
    locale: 'en',
    rtlLayout: false,
    container: false
};

export default config;
