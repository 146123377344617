/* eslint-disable */
import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { Grid } from '@mui/material';

import { gridSpacing } from '../../store/constant';
import { useDispatch, useSelector } from '../../store';

// assets
import MainCard from '../../ui-elements/MainCard';
import { useTheme } from '@emotion/react';

import BreadcrumbsNav from '../../ui-elements/BreadcrumbsTwo';
import { createAgent, getAllAgentTemplates } from '../../store/slices/agent';
import { LoadingButton } from '@mui/lab';
import AgentTemplateSkeletonLoader from '../../ui-elements/skeleton/AgentTemplateSkeletonLoader';

const AgentTemplateSelect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme();
    const { agentTemplates, templatesLoading, createAgentLoading } = useSelector((state) => state.agent);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = React.useState(null)

    React.useEffect(() => {
        async function fetch() {
            await dispatch(getAllAgentTemplates())
        }
        fetch()
    }, [])

    const handleCreateAgent = async (agentValue, index) => {

        try {
            setSelectedTemplateIndex(index)
            const res = await dispatch(createAgent(agentValue));
            navigate(`/dashboard/agents/${res?.data?._id || ""}`)
        }
        catch (error) {

        }
        finally {
            setSelectedTemplateIndex(null)
        }
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <BreadcrumbsNav />
                <Grid item xs={12} className='flex justify-center items-center'>
                    <MainCard
                        content={false}
                        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Grid container spacing={gridSpacing} className='justify-center items-center py-[4rem] px-4'>
                            <Grid item xs={12} lg={12}>
                                <div className='flex flex-col w-full justify-center items-center gap-2 mb-8'>
                                    <h1 className='text-2xl font-semibold text-center'>Choose Your Ready-Made AI Agent</h1>
                                    <p className='text-center text-tertiary'>
                                        Select from our wide range of template agents designed for specific<br className='hidden sm:block' />{' '}
                                        business needs. Simply pick, customize, and start calling!
                                    </p>
                                </div>
                                {!templatesLoading ?
                                    <Grid className='flex flex-col md:grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-10 justify-center items-center'>
                                        {agentTemplates && agentTemplates?.slice(1).map((template, index) => {
                                            const details = template?.agent_info
                                            const agentValue = template?.agent

                                            return (
                                                <div
                                                    key={index}
                                                    className={`min-h-[200px] min-w-[280px] w-[280px] flex flex-col gap-4 rounded-lg px-4 py-4 shadow-card-shadow border border-gray-200`}
                                                >
                                                    <div className='flex flex-col items-start justify-start gap-2'>
                                                        <div className='w-10 h-10 bg-primary rounded-[3px] flex items-center justify-center text-white text-xl'>
                                                            {details?.agent_name?.charAt(0).toUpperCase()}
                                                        </div>
                                                        <h2 className='text-xl font-semibold'>{details?.agent_name}</h2>
                                                        <p className={`text-left  text-tertiary`}>{details?.title}</p>
                                                    </div>

                                                    <hr className='bg-gray-200' />

                                                    <div name="features" className='flex flex-col gap-3 justify-start items-start px-4'>
                                                        <ul className='text-sm text-tertiary' style={{ listStyleType: 'disc' }} >
                                                            {details?.description.map((data, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        {data}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>

                                                    <LoadingButton
                                                        variant="contained"
                                                        size="medium"
                                                        loading={(createAgentLoading && selectedTemplateIndex === index)}
                                                        onClick={() => handleCreateAgent(agentValue, index)}
                                                    >
                                                        Select
                                                    </LoadingButton>
                                                </div>
                                            )
                                        })}
                                    </Grid>
                                    :
                                    <AgentTemplateSkeletonLoader />
                                }
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default AgentTemplateSelect;
